import React from "react"
import AdvantagesHeaderSection from "../../sections/AdvantagesHeaderSection"
import Layout from "../../components/Layout"
import SideNavMenuSection from "../../sections/SideNavMenuSection"
import ArticleGroupFooterSection from "../../sections/ArticleGroupFooterSection"
import UserSideNavSection from "../../sections/login_signup_side_navs/UserSideNavSection"
import SchoolSideNavSection from "../../sections/login_signup_side_navs/SchoolSideNavSection"
import { Link, navigate } from "gatsby"
import { formatDateTime } from "../../scripts/Utils"
import { useTranslation } from "react-i18next"
import ArrowLeftIconDark from "../../svg/arrow-left-dark.inline.svg"

const EventsListPage = props => {
  const metaKeywords = props.pageContext.events
    .map(event => event.metaKeywords)
    .join(", ")

  const metaDescription = props.pageContext.events
    .map(event => event.metaDescription)
    .join(", ")
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const getDate = (value, format) => {
    return formatDateTime(value, format)
  }

  const backToPage = () => {
    navigate(`/${lang}#upcomingEvent`)
  }

  return (
    <Layout
      wrapperClass={"school-item info-page"}
      pageTitle={`Smart Admissions Service`}
      keywordsContent={metaKeywords}
      descriptionContent={metaDescription}
      isSecondLayout={true}
    >
      <AdvantagesHeaderSection />
      <div className="white-block info-content-block container">
        <div className="row w-100">
          <div className="col-12 my-5 school-comparison-main-info school-comparison-main-info-title gap-3">
            <div
              onClick={backToPage}
              className="school-comparison-main-info-left"
            >
              <ArrowLeftIconDark className="arrow_left_dark" />
            </div>
            <div>
              {
                <div className="title-forgot-password">
                  {t("main.upcomingEvents.upcoming")}
                </div>
              }
            </div>
          </div>
        </div>
        <div className="row">
          {props.pageContext.events.map(item => (
            <Link
              key={item.id}
              to={`/${i18n.language}/event/${item.resolvedPath}`}
              className="px-7"
            >
              <div className="school-item-comparison-wrapper f-jb">
                <div className="school-item-comparison-image-description f-js row">
                  <img
                    src={item.resolvedImageUrl}
                    alt=""
                    className="school-comparison-image  col-lg-7"
                  />
                  <div className="school-comparison-main-info justify-content-start col">
                    <div className="saved-school-title">{item.title}</div>
                    <div className="saved-school-text">{item.address}</div>
                    <div className="saved-school-text">
                      {getDate(item.startTimeUtcWithTimezone, "DD MMM")} -{" "}
                      {getDate(item.endTimeUtcWithTimezone, "DD MMM YYYY")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <ArticleGroupFooterSection />
      <SideNavMenuSection />
      <UserSideNavSection />
      <SchoolSideNavSection />
    </Layout>
  )
}
export default EventsListPage
